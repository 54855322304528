// Company routes

const Company = () => import("@/views/Companies/Company.vue");
const Companies = () => import("@/views/Companies/Companies.vue");
const CompanyCreate = () => import("@/views/Companies/CompanyCreate.vue");
const CompanyEdit = () => import("@/views/Companies/CompanyEdit.vue");
const CompanyUsers = () => import("@/views/Companies/CompanyUsers.vue");
const UserCreate = () => import("@/views/User/UserCreate.vue");



const routes = [
  {
    path: "/Companies",
    meta: {
      auth: {
        roles: "Account Admin",
        forbiddenRedirect: "/403",
      },
    },
    name: "Companies",
    component: Companies,
  },
  {
    path: "/Companies/Create",
    meta: {
      auth: {
        roles: "Account Admin",
        forbiddenRedirect: "/403",
      },
    },
    name: "CompanyCreate",
    component: CompanyCreate,
  },

 
  {
    path: "/Companies/:id",
    meta: {
      auth: {
        roles: "Account Admin",
        forbiddenRedirect: "/403",
      },
    },
    redirect: (to) => {
      return "/Companies/" + to.params.id + "/Users";
    },
    name: "Company",
    component: Company,
    children: [
      {
        path: "Users",
        meta: {
          auth: {
            roles: "Account Admin",
          forbiddenRedirect: "/403",
          },
        },
        name: "CompanyUsers",
        component: CompanyUsers,
      },
      {
        path: "Users/Create",
        meta: {
          auth: {
            roles: ["Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
        name: "UserCreate",
        component: UserCreate,
      },
      {
        path: "/Companies/:id/Edit",
        meta: {
          auth: {
            roles: "Account Admin",
            forbiddenRedirect: "/403",
          },
        },
        name: "CompanyEdit",
        component: CompanyEdit,
      },
    ],
  },
];

export default routes;

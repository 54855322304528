<template>
  <n-navbar
    ref="navbar"
    class="navigation-main px-6"
    :drawer="true"
    fixed="top"
  >
    <template #logo>
      <img
        class="navbar__logo"
        src="../../assets/img/nymblscience-logo.svg"
        @click="handleLogoClick()"
      />

      <div
        v-if="
          (roles.includes('Account Admin') || roles.includes('System Admin')) &&
          location
        "
        class="h-full flex items-center ml-6"
      >
        <span v-if="location === 'united states'"
          ><img style="height: 18px" :src="usFlag" />
        </span>
        <span v-else-if="location === 'new zealand'"
          ><img style="height: 18px" :src="nzFlag" />
        </span>
      </div>
    </template>
    <template #nav-items-drawer>
      <!-- Account Admin -->
      <n-nav-item
        v-if="
          routeRoles.includes('Account Admin') &&
          roles.includes('Account Admin') &&
          !$route.path.includes('ContentAdmin')
        "
        to="/AccountAdmin/Enterprises"
        @click="closeMenu"
      >
        Enterprises
      </n-nav-item>
      <n-nav-item
        v-if="
          routeRoles.includes('Account Admin') &&
          roles.includes('Account Admin') &&
          !$route.path.includes('ContentAdmin')
        "
        to="/Companies"
        @click="closeMenu"
      >
        Companies
      </n-nav-item>
      <n-nav-item
        v-if="
          routeRoles.includes('Account Admin') &&
          roles.includes('Account Admin') &&
          !$route.path.includes('ContentAdmin')
        "
        to="/Users"
        @click="closeMenu"
      >
        Users
      </n-nav-item>
      <n-nav-item
        v-if="
          routeRoles.includes('Account Admin') &&
          roles.includes('Account Admin') &&
          !$route.path.includes('ContentAdmin')
        "
        to="/AccountAdmin/Eligibility"
        @click="closeMenu"
      >
        Eligibility
      </n-nav-item>

      <!-- Content Admin -->

      <n-nav-item
        v-if="routeRoles.includes('Content Admin')"
        to="/ContentAdmin/Localizator"
        class="flex"
        @click="closeMenu"
      >
        Localizator
      </n-nav-item>

      <n-nav-item
        v-if="
          routeRoles.includes('Content Admin') ||
          (routeRoles.includes('Account Admin') &&
            $route.path.includes('ContentAdmin'))
        "
        to="/ContentAdmin/Enterprises"
        class="flex"
        @click="closeMenu"
      >
        Enterprise Management
      </n-nav-item>

      <n-nav-item
        v-if="
          routeRoles.includes('Content Admin') ||
          (routeRoles.includes('Account Admin') &&
            $route.path.includes('ContentAdmin'))
        "
        to="/ContentAdmin/Surveys"
        @click="closeMenu"
      >
        Surveys
      </n-nav-item>
      <n-nav-item
        v-if="
          routeRoles.includes('Content Admin') ||
          (routeRoles.includes('Account Admin') &&
            $route.path.includes('ContentAdmin'))
        "
        to="/ContentAdmin/"
        :active="
          $route.path.includes('ContentAdmin') &&
          !$route.path.includes('Enterprises') &&
          !$route.path.includes('Factoids') &&
          !$route.path.includes('Surveys') &&
          !$route.path.includes('FeatureHighlights')
        "
        @click="closeMenu"
      >
        Training
      </n-nav-item>
      <n-nav-item
        v-if="
          routeRoles.includes('Content Admin') ||
          (routeRoles.includes('Account Admin') &&
            $route.path.includes('ContentAdmin'))
        "
        to="/ContentAdmin/Factoids"
        @click="closeMenu"
      >
        Factoids
      </n-nav-item>
      <n-nav-item
        v-if="
          routeRoles.includes('Content Admin') ||
          (routeRoles.includes('Account Admin') &&
            $route.path.includes('ContentAdmin'))
        "
        to="/ContentAdmin/FeatureHighlights"
        @click="closeMenu"
      >
        Feature Highlights
      </n-nav-item>

      <!-- System Admin -->

      <n-nav-item
        v-if="routeRoles === 'System Admin'"
        to="/SystemAdmin/Administrators"
        @click="closeMenu"
      >
        Administrators
      </n-nav-item>

      <n-nav-item
        v-if="routeRoles === 'System Admin'"
        to="/SystemAdmin/AppCompatibility"
        @click="closeMenu"
      >
        Compatibility
      </n-nav-item>
      <n-nav-item
        v-if="routeRoles === 'System Admin'"
        to="/SystemAdmin/FeatureFlags"
        @click="closeMenu"
      >
        Feature Flags
      </n-nav-item>
    </template>

    <template #nav-items>
      <navigation-profile-toggle
        :show-profile="showProfile"
        :company-name="companyName"
        tabindex="0"
        @click="toggleNavigationProfile"
        @keyup.enter="toggleNavigationProfile"
      >
        <transition name="slide">
          <navigation-profile
            v-if="showProfile"
            :company-name="companyName"
            :show-profile="showProfile"
            @logout="$emit('logout')"
            @close="showProfile = false"
          />
        </transition>
      </navigation-profile-toggle>
    </template>
  </n-navbar>
</template>

<script>
import NavigationProfile from "./NavigationProfile.vue";
import NavigationProfileToggle from "./NavigationProfileToggle.vue";

export default {
  components: {
    NavigationProfile,
    NavigationProfileToggle,
  },

  props: {
    companyName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showProfile: false,
      usFlag: require("../../assets/img/flags/US.svg"),
      nzFlag: require("../../assets/img/flags/NZ.svg"),
    };
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
    roles() {
      if (Object.keys(this.me).length === 0) {
        return [];
      }
      return this.$store.state.me.roles;
    },
    location() {
      return this.$store.state.location;
    },
    routeRoles() {
      return this.$route.meta.auth ? this.$route.meta.auth.roles : [];
    },
  },

  methods: {
    closeMenu() {
      let that = this;
      that.$refs.navbar.hide();
    },
    toggleNavigationProfile() {
      this.showProfile = !this.showProfile;
    },
    handleLogoClick() {
      // When clicked on logo, go to the default path for the active role
      const that = this;
      const urlParts = this.$route.path.split("/");
      const oldPath = this.$route.path;
      setTimeout(() => {}, 100);
      if (urlParts[1] === "User") {
        that.$router.go();
        return;
      }

      this.$router.push(`/${urlParts[1]}`);

      // If the default roles path is the same refresh the page
      setTimeout(() => {
        if (oldPath === that.$route.path) {
          that.$router.go();
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.navbar__logo {
  width: 100px;
}

.n-navbar-inner {
  flex-wrap: nowrap;
  max-width: 1000rem;
}

.btn.btn-icon-round {
  border-radius: 999rem;
  padding: 8px;
}

.n-navbar .nav-link {
  font-size: 0.9rem;
}

.n-nav-item-button {
  padding: 0 !important;
}

@media only screen and (max-width: 70rem) {
  .navigation-help-icon {
    margin-left: 0.6rem;
  }

  .n-navbar.fixed-top {
    min-height: 3rem;
  }
}
.navigation-main {
  @media (max-width: 900px) {
    position: absolute !important;
    width: 100%;
  }
}
#side-bar {
  @media (max-width: 900px) {
    display: flex !important;
    flex-wrap: wrap;
    // flex-direction: column;
    .nav-item {
      height: 60px;
    }
  }
}
</style>

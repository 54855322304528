
import modal from "./modal";
import queries from "./queries";
import log from "./log";

const mixed = {
  computed: {
    apiError() {
      return this.$store.state.apiError;
    },
  },
  mounted() {
    let input = document.querySelector("[autofocus]");
    if (input) {
      input.focus();
    }
  },
};

const mixins = [mixed, modal, queries, log];

export default mixins;

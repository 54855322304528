let apiUrl = process.env.VUE_APP_API_URL;

if (window.location.hostname.split(".").includes("test")) {
  apiUrl = "https://api-test.nymblscience.com";
}

// Support for pre-release testing https://preprod.nymblscience.com
if (window.location.hostname.split(".")[0] === "preprod") {
  apiUrl = "https://api-preprod.nymblscience.com";
}
console.log(apiUrl, "url apiUrl.js");

export { apiUrl };

<template>
  <div class="h-full w-full flex items-center justify-center flex-col">
    <svg
      id="successAnimation"
      class="animated"
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="130"
      viewBox="0 0 70 70"
    >
      <circle
        id="successAnimationCircle"
        cx="35"
        cy="35"
        r="24"
        stroke="#0C6A69"
        stroke-width="2"
        stroke-linecap="round"
        fill="transparent"
      />
      <polyline
        id="successAnimationCheck"
        stroke="#0C6A69"
        stroke-width="2"
        points="23 34 34 43 47 27"
        fill="transparent"
      />
    </svg>
    <p>Reset email sent successfuly.</p>
    <n-button
      autofocus
      class="shadow text-xs mb-2 mx-auto font-medium"
      rounded-xl
      :loading="loading"
      variant="primary"
      @click="$router.push('/SignIn')"
    >
      Sign in
    </n-button>
  </div>
</template>

<script>
export default {
  name: "ForgotPasswordSuccess",
};
</script>

<style lang="scss" scoped>
p {
  text-align: center;
  margin-bottom: 2rem;
  animation: 2s fadeIn;
}
.nymbl-btn {
  animation: 2s fadeIn;
}

$circle-length: 20rem;
$check-length: 10rem;

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: $circle-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: $check-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#successAnimationCircle {
  stroke-dasharray: $circle-length $circle-length;
  stroke: #0c6a69;
}

#successAnimationCheck {
  stroke-dasharray: $check-length $check-length;
  stroke: #0c6a69;
}

#successAnimationResult {
  fill: #0c6a69;
  opacity: 0;
}

#successAnimation.animated {
  animation: 1.2s ease-out 0s 1 both scaleAnimation;
  margin-top: -2rem;
  margin-bottom: 1rem;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2));
  #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
      0.6s linear 1.3s 1 both;
  }

  #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
      0.6s linear 1.3s 1 both;
  }

  #successAnimationResult {
    animation: 0.6s linear 1.3s both fadeIn;
  }
}
</style>

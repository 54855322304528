<template>
  <div>
    <h1 class="text-green-100 m-0 text-center text-base font-extrabold">
      Welcome to Nymbl!
    </h1>

    <form class="text-left" @enter="login()" @submit.prevent="login()">
      <div>
        <n-textbox
          id="email"
          v-model="state.username"
          border
          name="Email"
          label="Email:"
          :input-classes="{
            'rounded-xxs placeholder:italic text-xs py-0 h-8  border-gray-light': true,
            'border-red': isInvalid('username', v$),
          }"
          placeholder="name@example.com"
          feedback-classes="text-xxs"
          autocomplete="username"
          :error="getError('username', v$)"
          :invalid="isInvalid('username', v$)"
          @change="v$.username.$touch"
        >
          <label class="text-xs font-bold">Email:</label>
        </n-textbox>
      </div>
      <div>
        <n-textbox
          id="password"
          v-model="state.password"
          border
          :input-classes="{
            'rounded-xxs placeholder:italic text-xs py-0 h-8 border-gray-light': true,
            'border-red': isInvalid('password', v$),
          }"
          feedback-classes="text-xxs"
          name="Password"
          placeholder="Enter your password"
          type="password"
          label="Password:"
          autocomplete="current-password"
          :error="getError('password', v$)"
          :invalid="isInvalid('password', v$)"
        >
          <div class="flex w-full items-center">
            <label class="text-xs font-bold">Password:</label>
            <div class="text-right w-full justify-end items-start">
              <a
                class="cursor-pointer text-xxs font-bold text-green-100 hover:text-green-200 underline"
                @click="$router.push('/SignIn/ForgotPassword')"
                >Forgot your password?</a
              >
            </div>
          </div>
        </n-textbox>
      </div>

      <location-switcher @updateLocation="updateLocation" />

      <div class="n-form-item flex justify-center">
        <n-button
          autofocus
          class="shadow mb-2 mx-auto font-bold"
          rounded-xxl
          :loading="loading || loadingMfa"
          variant="primary"
          :disabled="
            Object.keys($store.state.anonymousDefaults).length === 0 ||
            isInvalid('password', v$) ||
            isInvalid('username', v$) ||
            !state.password ||
            !state.username
          "
          container-classes="text-xs py-1 px-12 font-medium"
          type="submit"
        >
          Sign in
        </n-button>
      </div>
    </form>
  </div>
</template>
<script>
import qs from "qs";
import { reactive } from "vue";

import LocationSwitcher from "@/components/LocationSwitcher.vue";
import { useValidation, getError, isInvalid } from "nymbl-ui/validation";
import { required, email, helpers } from "@vuelidate/validators";
import api from "@/api";
import { mfaState, updateMfaState } from "./mfaStore";

export default {
  name: "AuthForm",
  components: {
    LocationSwitcher,
  },

  setup() {
    const state = reactive({
      username: null,
      password: "",
    });

    const $externalResults = reactive({});

    const rules = {
      username: {
        required: helpers.withMessage("The Email field is required", required),
        email: helpers.withMessage("The Email field is required", email),
      },
      password: {
        required: helpers.withMessage(
          "The Password field is required",
          required
        ),
      },
    };

    const v$ = useValidation(rules, state, { $lazy: true, $externalResults });

    return {
      v$,
      state,
      getError,
      isInvalid,
      mfaState,
      updateMfaState,
    };
  },

  data() {
    return {
      location: "",
      loading: false,
      loadingMfa: false,
      data: {
        body: {
          grant_type: "password",
          client_id: "mainApp",
          client_secret: "",
          auth: "",
        },
      },
      rememberDevice: false,
    };
  },
  computed: {
    redirectPath() {
      if (this.$auth.redirect()) {
        return this.$auth.redirect().from.fullPath;
      }
      return false;
    },
    redirectPathExpire() {
      return this.$store.state.redirectPathExpire;
    },
    anonymousDefaults() {
      return this.$store.state.anonymousDefaults;
    },
    apiToCountryMappings() {
      return this.$store.getters.apiToCountryMappings;
    },
    hasValidRememberMeToken() {
      return this.$store.getters.hasValidRememberMeToken;
    },
    credentials() {
      const credentials = {
        client_id: "mainApp",
        client_secret: "",
      };
      if (this.hasValidRememberMeToken) {
        return qs.stringify({
          ...credentials,
          grant_type: "rememberme",

          token: localStorage.getItem("rememberMeToken").token,
        });
      } else {
        return qs.stringify({
          ...credentials,
          grant_type: "password",
          username: this.state.username.trim(),
          password: this.state.password,
          token: this.data.body.token,
        });
      }
    },
  },
  watch: {
    state: {
      handler() {
        this.state.username = this.state.username.trim();
      },
      deep: true,
    },
    // Cancel loading when Api Call has error.
    apiError(value) {
      const that = this;
      console.log(value);
      if (value === true) {
        that.loading = false;
      }
    },
  },
  created() {
    if (
      this.mfaState.mfaToken &&
      this.mfaState.username &&
      this.mfaState.password
    ) {
      console.log("mfaState", this.mfaState);
      this.loadingMfa = true;
      this.data.body.token = this.mfaState.mfaToken;
      this.state.username = this.mfaState.username;
      this.state.password = this.mfaState.password;
      this.location = this.mfaState.location;
      this.$nextTick(this.login());
    }
  },

  methods: {
    updateLocation(location) {
      const locationLocal = this.mfaState.location || location;
      this.location = locationLocal;
      this.$store.commit("updateLocation", locationLocal);
    },
    login() {
      const that = this;
      this.$nextTick(() => {
        this.v$.$validate().then((isValid) => {
          if (isValid) {
            that.loading = true;
            this.$auth
              .login({
                headers: {
                  "Content-type": "application/x-www-form-urlencoded",
                },
                data: this.credentials,
                staySignedIn: true,
                fetchUser: true,
                redirect: null,
              })
              .then(
                (result) => {
                  const scope = localStorage.getItem("scope");
                  if (scope.includes("mfa_required")) {
                    this.$router.push("/SignIn/MfaSetup");
                    return;
                  }

                  this.handlePortalLogin(result);
                },
                (error) => {
                  console.log(error, "error");

                  if (
                    error.response.data.error_description ===
                    "Valid MFA token is required"
                  ) {
                    if (this.mfaState.mfaToken) {
                      that.$nMessage({
                        type: "error",
                        message: "invalid MFA token",
                        showClose: true,
                      });
                    }
                    this.$router.push({
                      name: "AuthMfa",
                    });

                    this.updateMfaState("username", this.state.username);
                    this.updateMfaState("password", this.state.password);
                    this.updateMfaState("location", this.location);
                    return;
                  }

                  that.$nMessage({
                    type: "error",
                    message: error.response.data.error_description,
                    showClose: true,
                  });

                  that.loading = false;
                }
              );
          }
        });
      });
    },
    secondaryLogin(primaryToken) {
      let url;
      if (!this.apiToCountryMappings) {
        console.error("Couldn't add Secondary token: No System Defaults");
        return;
      }
      if (this.location.toLowerCase() === "united states") {
        url = this.apiToCountryMappings["new zealand"];
      }
      if (this.location.toLowerCase() === "new zealand") {
        url = this.apiToCountryMappings["united states"];
      }

      if (!url) {
        console.log("Other than US/NZ selected. No secondary API.");
        return;
      }
      // Secondary API uses same token as Primary API
      const data = {
        url,
        access_token: primaryToken,
      };

      this.$store.commit("updateSecondaryToken", data);
    },
    handlePortalLogin(result) {
      const that = this;
      console.log("Login /Me:", result.data);

      // Save current token
      this.$store.commit("updatePrimaryToken", this.$auth.token());

      // Save current url
      this.$store.commit("updatePrimaryUrl", this.axios.defaults.baseURL);
      console.log(this.axios.defaults.baseURL);

      // Save location to Store
      this.$store.commit("updateLocation", this.location);

      // Close All error messages
      that.$nMessage.closeAll();
      const params = {
        includeFullTrainingProgramHierarchy: true,
        includeCompanies: true,
      };
      api.get("/Me", params).then(async ({ data }) => {
        const user = data;
        console.log("/Me:", user);

        // Fetch the secondary token (nz/us)
        if (
          user.roles.includes("System Admin") ||
          user.roles.includes("Account Admin")
        ) {
          this.secondaryLogin(this.$auth.token());
        }

        // Redirect to a route originally accessed
        if (
          this.redirectPath &&
          this.redirectPath !== "/" &&
          this.redirectPath !== "/SignIn"
        ) {
          console.log("redirectPath :", this.redirectPath);
          that.$router.push(this.redirectPath);
        } else if (
          this.redirectPathExpire &&
          this.redirectPathExpire !== "/" &&
          this.redirectPathExpire !== "/SignIn"
        ) {
          console.log("redirectPathExpire:", this.redirectPathExpire);
          that.$router.push(this.redirectPathExpire);
        }

        // Two /me calls to get correct permissions. #TODO
        else if (user.roles.includes("System Admin")) {
          that.$router.push("/SystemAdmin");
        } else if (user.roles.includes("Account Admin")) {
          that.$router.push("/Users");
        } else if (user.roles.includes("Coach")) {
          that.$router.push("/Coach");
        } else if (user.roles.includes("Content Admin")) {
          that.$router.push("/ContentAdmin");
        } else {
          that.loading = false;
          that.$nMessage({
            type: "error",
            message: "Please login with another account.",
            showClose: true,
            duration: 4000,
          });
          window.App.logout();
          return;
        }

        that.$store.commit("updateMe", user);
      });
    },

    getRememberMeToken() {
      api
        .post("/Me/RememberMe")
        .then((result) => {
          localStorage.setItem("rememberMeToken", JSON.stringify(result.data));
        })
        .catch((err) => {
          console.log(err);

          throw new Error("Couldn't fetch rememberMe token");
        });
    },
  },
};
</script>

<style scoped>
form {
  text-align: left;
}
</style>

import moment from "moment-mini";
export default {
  created() {
    this.addClickEventListener();
  },
  mounted() {
    this.recordClick();
    setInterval(this.isActive, 10000);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.recordClick);
  },
  methods: {
    isActive() {
      const lastClick = moment(JSON.parse(localStorage.getItem("lastClick")));

      // Don't expire if Remember Me selected
      if (
        !this.$store.getters.hasValidRememberMeToken &&
        lastClick &&
        !lastClick.isAfter(moment().subtract(30, "minutes"))
      ) {
        this.handleExpire();
        return;
      }
      if (this.loggedIn) {
        this.refreshToken();
      }
    },
    addClickEventListener() {
      document.addEventListener("click", this.recordClick);
    },

    recordClick() {
      localStorage.setItem("lastClick", JSON.stringify(moment()));
    },
    handleExpire() {
      if (this.$auth.check()) {
        console.log("Session Expired.");
        this.$nMessage.closeAll();
        this.$nMessage({
          type: "error",
          message: "Session has expired. Please log in again.",
          showClose: true,
          duration: 5000,
        });
        this.logout("expire");
        localStorage.setItem("lastClick", null);
      }
    },
  },
};

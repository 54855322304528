import moment from "moment-mini";
import qs from "qs";
import axios from "axios";

export default {
  data() {
    return {
      isTokenRefreshing: null,
      isTokenSecondaryRefreshing: null,
    };
  },
  methods: {
    getParams(refreshToken) {
      return qs.stringify({
        client_id: "mainApp",
        client_secret: "",
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      });
    },
    refreshToken() {
      const that = this;
      const expires = moment(localStorage.getItem("expires"));
      const today = moment();
      const diff = expires.diff(today, "minutes");
      if (diff < 10 && !that.isTokenRefreshing) {
        console.log("Refreshing authentication token");
        that.isTokenRefreshing = true;
        that.refreshPrimaryToken();
      }
    },
    refreshPrimaryToken() {
      const that = this;

      let token = localStorage.getItem("access_token");
      const refreshToken = localStorage.getItem("refresh_token");

      const data = this.getParams(refreshToken);
      if (token) {
        // New Api axios instance to have Content Type overridden.

        const api = axios.create({
          baseURL: this.$store.state.primaryUrl,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        api
          .post("token", data)
          .then(({ data }) => {
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("refresh_token", data.refresh_token);
            localStorage.setItem("expires", data[".expires"]);
            if (!window.isSecondaryApi) {
              that.$auth.token(null, data.access_token);
            }
            that.$store.commit("updatePrimaryToken", data.access_token);

            console.log("Authentication token has been refreshed");
            that.isTokenRefreshing = false;
          })
          .catch((error) => {
            that.$nMessage.closeAll();
            console.log("Refresh Token Error", error);
            that.$nMessage({
              type: "error",
              message: "Session has expired. Please log in again.",
              showClose: true,
              duration: 5000,
            });
            that.logout("expire");
            that.$store.commit("setApiError", true);
            that.isTokenRefreshing = false;
          });
      }
    },
  },
};

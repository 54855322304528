import { reactive } from "vue";

const stateInitial = {
  username: null,
  password: null,
  mfaToken: null,
  location: null,
};

let mfaState = reactive({ ...stateInitial });

const updateMfaState = (property, value) => {
  mfaState[property] = value;
};

const resetMfaState = () => {
  Object.assign(mfaState, stateInitial);
};

export { mfaState, updateMfaState, resetMfaState };

export default {
  methods: {
    updateQuery(query) {
      this.$router.push({
        query: { ...this.$route.query, ...query },
      });
    },
    removeQuery(queryToRemove) {
      const query = Object.assign({}, this.$route.query);
      if (!query[queryToRemove]) {
        return;
      }
      delete query[queryToRemove];
      this.$router.replace({ query });
    },
  },
};

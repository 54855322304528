<template>
  <div
    style="background: #f2f2f2"
    class="login-backdrop absolute top-0 bottom-0 left-0 right-0 p-2 sm:p-10 md:p-10 font-roboto xl:flex xl:items-center xl:justify-center"
  >
    <div
      v-show="loaded"
      id="login-form-container"
      class="rounded-xl bg-white justify-center w-full h-auto xs:flex xs:flex-col xs:min-h-full p-2"
    >
      <div class="logo-container text-center w-full">
        <img
          id="nymbl-sign-in-logo"
          src="../../assets/img/nymblscience-logo.svg"
          alt="Nymbl Science Logo"
          style="max-width: 6rem"
          class="m-auto mb-4 xxs:mt-6 xs:mt-0"
        >
      </div>
      <div class="xl:flex xl:justify-center items-stretch w-full">
        <div class="hidden xl:flex items-end">
          <img
            class="w-100 mr-16 mb-2"
            src="../../assets/img/nymbl-walking-illustration.png"
          >
        </div>
        <div class="login-slot-container mx-auto xl:mx-0">
          <div
            style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)"
            class="login-slot rounded-lg border border-gray-110 py-1 px-4 md:py-2 md:px-4 mx-auto xl:mx-0 h-full w-full"
          >
            <slot />
          </div>
        </div>
        <div class="hidden xl:flex items-end">
          <img
            class="w-100 ml-16 mb-2"
            src="../../assets/img/nymbl-gardening-illustration.png"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthContainer",
  data() {
    return {
      loading: false,
      loaded: false,
    };
  },
  created() {
    // Add temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.add("new-ui-login");
    body.classList.add("new-ui-login");
  },
  mounted() {
    this.loaded = true;
  },
  beforeUnmount() {
    // Remove temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.remove("new-ui-login");
    body.classList.remove("new-ui-login");
  },
};
</script>
<style lang="scss">
.new-ui-login {
  color: #000 !important;
  font-size: 24px !important;
  letter-spacing: -0.17px !important;
  .n-textbox .n-textbox__label-text {
    letter-spacing: -0.17px !important;
    color: #000 !important;
  }
}
#login-form-container {
  @media (min-width: 1748px) {
    width: 1648px !important;
    height: 860px !important;
    min-height: auto !important;
  }
}
.login-slot-container {
  width: auto;
  height: auto;
  @media (min-width: 30rem) {
    width: 17rem;
    height: 20rem;
  }
}
</style>

<template>
  <div class="n-spacer" />
</template>
<script>
export default {
  name: "Spacer",
};
</script>

<style lang="scss" scoped>
.n-spacer {
  padding: 1rem 0;
}
</style>
export default {
  methods: {
    nModalOpen(ref) {
      this.$refs[ref].open();
    },
    nModalClose(ref) {
      this.$refs[ref].close();
    },
  },
};

// Content Admin routes

const ContentAdmin = () => import("@/views/ContentAdmin/ContentAdmin.vue");
const ProgramProfiles = () =>
  import("@/views/ContentAdmin/ProgramProfiles.vue");
const ProgramProfileDetail = () =>
  import("@/views/ContentAdmin/ProgramProfileDetail.vue");
const TrainingPrograms = () =>
  import("@/views/ContentAdmin/TrainingPrograms.vue");
const TrainingProgramDetail = () =>
  import("@/views/ContentAdmin/TrainingProgramDetail.vue");
const TrainingLevels = () => import("@/views/ContentAdmin/TrainingLevels.vue");
const TrainingLevelDetail = () =>
  import("@/views/ContentAdmin/TrainingLevelDetail.vue");

const Exercises = () => import("@/views/ContentAdmin/Exercises.vue");

const Factoids = () => import("@/views/ContentAdmin/Factoids.vue");
const Surveys = () => import("@/views/ContentAdmin/Surveys.vue");

const FeatureHighlights = () =>
  import("@/views/ContentAdmin/FeatureHighlights.vue");

const Cognitive = () => import("@/views/ContentAdmin/Cognitive.vue");
const CognitiveForm = () => import("@/views/ContentAdmin/CognitiveForm.vue");

const QuestionsTrivia = () =>
  import("@/views/ContentAdmin/QuestionsTrivia.vue");

const PromotionSchemes = () =>
  import("@/views/ContentAdmin/PromotionSchemes.vue");

const Enterprises = () => import("@/views/Enterprises/Enterprises.vue");

const Localizator = () => import("@/views/ContentAdmin/Localizator.vue");


const routes = [
  {
    path: "/ContentAdmin",
    meta: {
      auth: {
        roles: ["Content Admin", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
    name: "ContentAdmin",
    component: ContentAdmin,
    redirect: "/ContentAdmin/TrainingProgramProfiles",
    children: [
      {
        path: "TrainingProgramProfiles",
        component: ProgramProfiles,
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
      },
      {
        path: "TrainingProgramProfiles/:id",
        component: ProgramProfileDetail,
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
      },
      {
        path: "TrainingPrograms",
        component: TrainingPrograms,
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
      },
      {
        path: "TrainingPrograms/:id",
        component: TrainingProgramDetail,
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
      },
      {
        path: "TrainingLevels",
        component: TrainingLevels,
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
      },
      {
        path: "TrainingLevels/:id",
        component: TrainingLevelDetail,
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
      },
      {
        path: "PromotionSchemes",
        component: PromotionSchemes,
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
      },
      {
        path: "Exercises",
        component: Exercises,
        name: "Exercises",
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
      },

      {
        path: "Cognitive",
        component: Cognitive,
        name: "Cognitive",
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
      },
      {
        path: "/ContentAdmin/Cognitive/:id/QuestionsTrivia/:questionCount",
        component: QuestionsTrivia,
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
        props: true,
      },
      {
        path: "/ContentAdmin/Cognitive/Create",
        component: CognitiveForm,
        name: "CognitiveCreate",
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
        props: true,
      },
      {
        path: "/ContentAdmin/Cognitive/:id/Edit",
        component: CognitiveForm,
        name: "CognitiveEdit",
        meta: {
          auth: {
            roles: ["Content Admin", "Account Admin"],
            forbiddenRedirect: "/403",
          },
        },
        props: true,
      },


    ],
  },
  {
    path: "/ContentAdmin/Localizator",
    component: Localizator,
    name: "Localizator",
    meta: {
      auth: {
        roles: ["Content Admin", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/ContentAdmin/Enterprises",
    component: Enterprises,
    name: "Enterprises",
    meta: {
      auth: {
        roles: ["Content Admin", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/ContentAdmin/Surveys",
    component: Surveys,
    meta: {
      auth: {
        roles: ["Content Admin", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/ContentAdmin/FeatureHighlights",
    component: FeatureHighlights,
    meta: {
      auth: {
        roles: ["Content Admin", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/ContentAdmin/Factoids",
    component: Factoids,
    meta: {
      auth: {
        roles: ["Content Admin", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
  },

];

export default routes;

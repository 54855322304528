<template>
  <div>
    <h1 class="text-green-100 m-1 text-center text-base font-extrabold">
      {{ isMFASetupScreen ? "Verify Authenticator" : "2FA" }}
    </h1>
    <p class="text-xs mt-2 mb-2 text-center">
      Enter the 6-digit code provided by the authenticator app
    </p>

    <div
      id="mfa-digits"
      class="flex justify-between my-10"
      @paste.prevent="onPaste"
    >
      <n-textbox
        v-for="(digit, index) in digits"
        :key="index"
        :ref="index.toString()"
        v-model="digit.digit"
        :autofocus="index === 0"
        :maxlength="0"
        pattern="\d"
        :show-max-lengwth="false"
        type="number"
        style="max-width: 50px"
        placeholder="0"
        @focus="digit.digit = null"
        @input="focusNext(index, digit.digit)"
        @keydown.delete="handleDelete(index, digit.digit)"
      />
    </div>

    <n-modal
      ref="modal"
      title="Recovery Codes"
      @close="goToSignIn"
    >
      <p class="mb-6 leading-5">
        Backup your recovery codes in case you lose access to your Authenticator
        app
      </p>
      <div class="flex flex-col">
        <div
          v-for="code in recoveryCodes"
          :key="code"
          class="mb-2 text-sm"
        >
          {{ code }}
        </div>
      </div>
      <template #footer>
        <n-button @click="goToSignIn">
          Sign In
        </n-button>
      </template>
    </n-modal>

    <div class="text-center">
      <n-button
        v-if="isMFASetupScreen"
        container-classes="text-xs py-1 px-12 font-medium"
        class="shadow mb-2 mx-auto font-bold"
        :disabled="!code"
        :loading="loading"
        @click="verifyMfa"
      >
        Verify
      </n-button>

      <n-button
        v-else
        class="shadow mb-2 mx-auto font-bold"
        container-classes="text-xs py-1 px-12 font-medium"
        :disabled="!code"
        :loading="loading"
        @click="signIn"
      >
        Sign In
      </n-button>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { updateMfaState } from "./mfaStore";

export default {
  name: "AuthMfa",
  setup() {
    return { updateMfaState };
  },
  data() {
    return {
      digits: Array(6)
        .fill(0)
        .map(() => {
          return { digit: null };
        }),
      recoveryCodes: [],
      loading: false,
    };
  },
  computed: {
    isMFASetupScreen() {
      return this.$route.name === "AuthMfaSetupVerify";
    },
    code() {
      if (
        this.digits.some(
          (digit) =>
            digit.digit === null || typeof parseInt(digit.digit) !== "number"
        )
      ) {
        return null;
      }
      return this.digits.map((digit) => digit.digit).join("");
    },
  },
  mounted() {
    console.log(this.$route);
  },

  methods: {
    focusNext(index, value) {
      if (!value) {
        return;
      }
      const refKey = (index + 1).toString();

      if (index === 5) {
        const selectors = document.querySelector(".n-button");
        this.$nextTick(() => selectors.focus());
        return;
      }
      this.$refs[refKey][0].focus();
    },
    verifyMfa() {
      this.loading = true;
      const data = {
        token: this.code,
      };
      api
        .post(`/Authorization/MFA/Verify`, data)
        .then(({ data }) => {
          this.recoveryCodes = data.recoveryCodes;
          this.$nextTick(() => {
            this.openRecoveryCodeModal();
          });
        })
        .catch(this.handleError)
        .finally(() => {
          this.loading = false;
        });
    },
    handleError() {
      this.$nMessage.closeAll();
      this.$nMessage({
        type: "error",
        message: "Invalid MFA token",
        showClose: true,
        duration: 5000,
      });
    },
    openRecoveryCodeModal() {
      this.$refs.modal.open();
    },
    closeRecoveryCodeModal() {
      this.$refs.modal.close();
    },
    signIn() {
      this.updateMfaState("mfaToken", this.code);
      this.$nextTick(() => this.$router.push({ name: "AuthForm" }));
    },

    goToSignIn() {
      this.closeRecoveryCodeModal();
      this.$nextTick(() => {
        this.$router.push({ name: "AuthForm" });
      });
    },
    handleDelete(index) {
      const refKey = (index - 1).toString();
      if (refKey === "-1") {
        return;
      }

      this.$refs[refKey][0].focus();
    },
    onPaste(e) {
      const pastedData = e.clipboardData.getData("text");
      console.log(pastedData.length);
      if (pastedData.length < 6) {
        return;
      }
      this.digits = pastedData
        .split("")
        .slice(0, 6)
        .map((digit) => {
          return { digit };
        });
    },
  },
};
</script>
<style lang="scss">
#mfa-digits {
  .n-textbox__input {
    border: 1px solid rgb(183, 183, 183) !important;
    border-radius: 4px !important;
    text-align: center !important;
  }
}
</style>

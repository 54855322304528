import { createVNode, render } from 'vue';
import NConfirm from '@/components/NConfirm.vue';

export default {
    install(app) {
        const handler = async options => {
            let node = null;
            const container = document.createElement('div');

            const response = await new Promise(resolve => {
                const vm = createVNode(NConfirm, {
                    ...options,
                    resolve,
                });
                render(vm, container);

                node = container.firstElementChild;
                document.body.appendChild(node);
            });

            document.querySelector('body').removeChild(node);

            return response;
        };

        app.provide('confirm', handler);
    },
};
import { createApp } from "vue";
import { createPinia } from "pinia";

import VueTippy from "vue-tippy";
// or
// import { plugin as VueTippy } from 'vue-tippy'
import "tippy.js/dist/tippy.css"; // optional for styling

import VueScrollTo from "vue-scrollto";


import NymblUI from "nymbl-ui";

import router from "./router";
import http from "./plugins/http";
import App from "./App.vue";

import auth from "./plugins/auth.js";
import nconfirm from "./plugins/nconfirm.js";


import { store } from "./store.js";

// Import Nymbl Vue UI kit from organization GitHub npm package.
import "nymbl-ui/dist/nymbl-ui.css";

// Import Tailwind
import "@/assets/tailwind.css";

import mixins from "./mixins";

// Import Styles
import "./assets/sass/main.scss";

import Spacer from "@/components/Spacer.vue";

const createAdminPortal = () => {
  let app = createApp(App);

  app.use(http);
  app.use(router);
  app.use(store);
  app.use(createPinia());
  app.use(VueTippy);

  const { Components, Plugins } = NymblUI;

  Object.keys(Components).forEach((name) => {
    app.component(name, Components[name]);
  });

  app.component("Spacer", Spacer);

  app.directive('scroll-to', VueScrollTo)

  app.use(nconfirm);
  app.use(Plugins.nmessage);

  app.use(auth);

  app.mount("#app");

  mixins.forEach((mixin) => {
    app.mixin(mixin);
  });
  

};

createAdminPortal();

<template>
  <auth-container>
    <div class="w-full h-full">
      <transition name="slide-fade">
        <router-view />
      </transition>
    </div>
  </auth-container>
</template>

<script>
import AuthContainer from "@/views/Auth/AuthContainer.vue";
export default {
  name: "SignIn",
  components: {
    AuthContainer,
  },
  data() {
    return {
      loaded: false,
      logoLoaded: false,
      forgotPassword: false,
      forgotPasswordSuccess: false,
    };
  },
  computed: {
    apiError() {
      return this.$store.state.apiError;
    },
  },
  watch: {
    // Cancel loading when Api Call has error.
    apiError(value) {
      const that = this;
      console.log(value);
      if (value === true) {
        that.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/colors.scss";

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: opacity 400ms ease;
  transition-delay: 50ms;
}
.slide-fade-leave-active {
  transition: opacity 0s ease;
  display: none;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10rem);
  opacity: 0;
}
.slide-fade-leave-to {
  // transform: translateX(-10rem);
  opacity: 0;
}
</style>

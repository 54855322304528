<template>
  <div>
    <h1 class="text-green-100 m-0 text-center text-base font-extrabold">
      Reset Password
    </h1>
    <p class="text-sm mb-2 mt-4">
      Enter your email address and we’ll send you instructions to reset your
      password.
    </p>
    <form
      class="mt-6 mb-3"
      @submit.prevent="sendResetEmail()"
    >
      <div class="n-form-item">
        <n-textbox
          v-model="state.username"
          name="Email"
          label="Email"
          feedback-classes="text-xxs"
          :placeholder="'name@example.com'"
          :input-classes="{
            'rounded-xxs placeholder:italic text-xs py-0 h-8  border-gray-light': true,
            'border-red': isInvalid('username', v$),
          }"
          :error="getError('username', v$)"
          :invalid="isInvalid('username', v$)"
          @change="v$.username.$touch"
        >
          <label class="text-xs font-bold">Email:</label>
        </n-textbox>
      </div>
      <div class="flex justify-center">
        <n-button
          :loading="loading"
          type="submit"
          container-classes="text-xs py-1 px-8 font-medium"
          class="shadow text-xxs mb-2 mx-auto"
          rounded-xl
          @click="sendResetEmail()"
        >
          Send Instructions
        </n-button>
      </div>
    </form>
    <div class="text-center my-6">
      <a
        class="cursor-pointer font-bold text-green-100 hover:text-green-200 py-4 text-sm underline"
        @click="$router.push('/SignIn')"
      >Sign In</a>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { reactive } from "vue";

import { useValidation, getError, isInvalid } from "nymbl-ui/validation";
import { required, email, helpers } from "@vuelidate/validators";
export default {
  name: "ForgotPassword",

  setup() {
    const state = reactive({
      username: null,
    });

    const $externalResults = reactive({});

    const rules = {
      username: {
        required: helpers.withMessage("The Email field is required", required),
        email: helpers.withMessage("The Email field is required", email),
      },
    };

    const v$ = useValidation(rules, state, { $lazy: true, $externalResults });

    return {
      v$,
      state,
      getError,
      isInvalid,
    };
  },
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    sendResetEmail() {
      const that = this;
      this.v$.$validate().then((isValid) => {
        if (isValid) {
          that.loading = true;
          const params = {
            email: this.state.username,
            source: "Dashboard",
          };
          api.get(`/Auth/PasswordResetRequest`, params).then(() => {
            that.loading = false;
            that.$router.push("/SignIn/ResetEmailSent");
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  text-align: left;
}
form {
  text-align: left;
}
</style>

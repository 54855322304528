<template>
  <div class="location-switcher">
    <div v-if="countries.length > 0">
      <n-select
        v-model="selected"
        name="Location"
        :options="countries"
        type="image"
        container-classes="text-xs min-h-0 h-6"
        label="Company Location"
        :disabled="countries.length < 1"
        placeholder="Select Location"
        @change="changeLocation"
      >
        <label class="text-xs font-bold">Location:</label>
      </n-select>
    </div>
    <div v-else>
      <n-select
        :options="[]"
        type="image"
        container-classes="text-xs min-h-0 h-6"
        label="Company Location"
        placeholder="Select Location"
      >
        <label class="text-xs font-bold">Location:</label>
      </n-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isResetPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: "united states",
      baseURL: "",
    };
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    },
    isTestEnv() {
      if (this.env === "production") {
        return false;
      }
      return true;
    },
    anonymousDefaults() {
      return this.$store.state.anonymousDefaults;
    },
    apiToCountryMappings() {
      return this.$store.getters.apiToCountryMappings;
    },
    countries() {
      const capitalize = (string) => {
        const stringArray = string.split(" ");
        const capitalizedStringArray = stringArray.map(
          (string) => string.charAt(0).toUpperCase() + string.slice(1)
        );
        return capitalizedStringArray.join(" ");
      };

      if (Object.keys(this.anonymousDefaults).length < 1) {
        return [];
      }

      const apiToCountryMappings = { ...this.apiToCountryMappings };
      const countries = Object.keys(apiToCountryMappings).map((country) => {
        return {
          label: capitalize(country),
          image: this.getFlag(country),
          value: country,
        };
      });
      return countries;
    },
  },
  watch: {
    apiToCountryMappings(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          if (this.isResetPassword) {
            this.$emit("updateLocation", this.selected);
          }
          this.changeApi();
        });
      }
    },
  },
  created() {
    this.baseURL = this.axios.defaults.baseURL;
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("updateLocation", this.selected);
    });
  },
  methods: {
    changeLocation() {
      if (!this.apiToCountryMappings) {
        return;
      }
      // let locationValue = this.apiToCountryMappings[this.selected];

      this.$emit("updateLocation", this.selected);

      if (this.selected) {
        this.changeApi();
      }
    },
    changeApi() {
      if (!this.apiToCountryMappings || !this.selected) {
        return;
      }
      let selected = this.apiToCountryMappings[this.selected];
      if (!selected) {
        return;
      }
      this.updateBaseURL(selected);
    },

    updateBaseURL(url) {
      this.baseURL = url;
      this.axios.defaults.baseURL = url;
      console.log("Updating axios base url to ", url);
    },
    getFlag(country) {
      if (country.toLowerCase() === "united states") {
        return require("../assets/img/flags/US.svg");
      }
      if (country.toLowerCase() === "new zealand") {
        return require("../assets/img/flags/NZ.svg");
      }
      if (country.toLowerCase() === "canada") {
        return require("../assets/img/flags/CA.svg");
      }
      if (country.toLowerCase() === "united kingdom") {
        return require("../assets/img/flags/GB.svg");
      }
      return require("../assets/img/flags/pixel.svg");
    },
  },
};
</script>
<style>
.n-select-option__image-object {
  width: 30px;
  height: 20px;
}
</style>

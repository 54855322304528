<template>
  <div
    style="background: #f2f2f2"
    class="absolute top-0 bottom-0 left-0 right-0 p-4 sm:p-10 md:p-10 flex items-center justify-center"
  >
    <div class="max-w-full md:max-w-xl lg:max-w-3xl mx-auto">
      <img
        style="max-width: 8rem"
        class="logo mx-auto mb-6"
        src="../assets/img/nymblscience-logo.svg"
      >

      <div
        class="rounded-xl shadow xs:p-4 md:p-8 xl:p-12 text-center bg-white flex"
      >
        <div class="sm:p-10 sm:py-10">
          <h1
            class="text-sm sm:text-lg md:text-lg lg:text-xl font-bold m-0 mb-5 lg:mb-8"
          >
            Page not found
          </h1>
          <h2 class="text-sm sm:text-lg mb-5 lg:mb-8 lg:max-w-lg">
            We can't seem to find the page you're looking for. Check the URL,
            there might be a typo.
          </h2>

          <n-button
            class="shadow mb-2 mx-auto font-bold"
            container-classes="text-xs py-0 sm:px-6   lg:text-base font-bold"
            rounded-xxl
            @click="$router.push('/')"
          >
            Go to Homepage
          </n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  created() {
    // do something after creating vue instance
    this.$store.commit("setLoading", false);
    // Add temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.add("new-ui-404");
    body.classList.add("new-ui-404");
  },
  beforeUnmount() {
    // Remove temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.remove("new-ui-404");
    body.classList.remove("new-ui-404");
  },
};
</script>
<style lang="scss">
.new-ui-404 {
  color: #000 !important;
  font-size: 24px !important;
  letter-spacing: -0.17px !important;
  .n-textbox .n-textbox__label-text {
    letter-spacing: -0.17px !important;
    color: #000 !important;
  }
}
</style>

// System Admin routes
const AppCompatibility = () =>
  import("@/views/SystemAdmin/AppCompatibility.vue");
const FeatureFlags = () => import("@/views/SystemAdmin/FeatureFlags.vue");
const FeatureKeys = () => import("@/views/SystemAdmin/FeatureKeys.vue");
const FeatureOverrides = () =>
  import("@/views/SystemAdmin/FeatureOverrides.vue");

  const Administrators = () =>
  import("@/views/SystemAdmin/Administrators.vue");

const routes = [
  {
    path: "/SystemAdmin/Administrators",
    meta: {
      auth: {
        roles: "System Admin",
        forbiddenRedirect: "/403",
      },
    },
    component: Administrators,

  },
  {
    path: "/SystemAdmin",
    meta: {
      auth: {
        roles: "System Admin",
        forbiddenRedirect: "/403",
      },
    },
    redirect: "/SystemAdmin/AppCompatibility",
  },
  {
    path: "/SystemAdmin/AppCompatibility",
    meta: {
      auth: {
        roles: "System Admin",
        forbiddenRedirect: "/403",
      },
    },
    name: "AppCompatibility",
    component: AppCompatibility,
  },
  {
    path: "/SystemAdmin/FeatureFlags",
    meta: {
      auth: {
        roles: "System Admin",
        forbiddenRedirect: "/403",
      },
    },
    name: "FeatureFlags",
    component: FeatureFlags,
    redirect: "/SystemAdmin/FeatureFlags/FeatureKeys",
    children: [
      {
        path: "FeatureKeys",
        component: FeatureKeys,
        name: "FeatureKeys",
        meta: {
          auth: {
            roles: "System Admin",
            forbiddenRedirect: "/403",
          },
        },
      },
      {
        path: "Overrides",
        name: "FeatureOverrides",
        component: FeatureOverrides,
        meta: {
          auth: {
            roles: "System Admin",
            forbiddenRedirect: "/403",
          },
        },
      },
    ],
  },
];

export default routes;

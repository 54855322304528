const User = () => import("@/views/User/User.vue");
const UserEdit = () => import("@/views/User/UserEdit.vue");

const UserTrainingSessions = () => import("@/views/User/UserTrainingSessions.vue");

const routes = [
  {
    path: "/User/:id",
    meta: {
      auth: {
        roles: ["Coach", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
    component: User,
  },
  {
    path: "/User/Edit/:id",
    meta: {
      auth: {
        roles: ["Coach", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
    name: "EditUser",
    component: UserEdit,
    props: true,
  },

  {
    path: "/User/:id/TrainingSessions",
    meta: {
      auth: {
        roles: ["Coach", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
    name: "UserTrainingSessions",
    component: UserTrainingSessions,
    props: true,
  }
]

export default routes;

const Users = () => import("@/views/AccountAdmin/Users.vue");
const CreateCoach = () => import("@/views/AccountAdmin/CreateCoach.vue");

const EligibilityAdmin = () =>
  import("@/views/AccountAdmin/EligibilityAdmin.vue");

const Enterprises = () => import("@/views/Enterprises/Enterprises.vue");
const Enterprise = () => import("@/views/Enterprises/Enterprise.vue");
const EnterpriseReferral = () =>
  import("@/views/Enterprises/EnterpriseReferral.vue");

const routes = [
  {
    path: "/Users",
    meta: {
      auth: {
        roles: ["Coach", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
    component: Users,
  },
  {
    path: "/Coach/Create",
    name: "CreateCoach",
    meta: {
      auth: {
        roles: ["Coach", "Account Admin"],
        forbiddenRedirect: "/403",
      },
    },
    component: CreateCoach,
  },
  {
    path: "/AccountAdmin/Eligibility",
    meta: {
      auth: {
        roles: "Account Admin",
        forbiddenRedirect: "/403",
      },
    },
    name: "EligibilityAdmin",
    component: EligibilityAdmin,
  },
  {
    path: "/AccountAdmin/Enterprises",
    props: true,
    meta: {
      auth: {
        roles: "Account Admin",
        forbiddenRedirect: "/403",
      },
    },
    name: "EnterprisesAccountAdmin",
    component: Enterprises,
  },
  {
    path: "/AccountAdmin/Enterprises/:id",
    props: true,
    meta: {
      auth: {
        roles: "Account Admin",
        forbiddenRedirect: "/403",
      },
    },
    name: "Enterprise",
    component: Enterprise,
  },
  {
    path: "/AccountAdmin/Enterprises/:id/ReferralSources",
    props: true,
    meta: {
      auth: {
        roles: "Account Admin",
        forbiddenRedirect: "/403",
      },
    },
    name: "EnterpriseReferral",
    component: EnterpriseReferral,
  },
];

export default routes;

<template>
  <div class="restricted-page">
    <img
      class="logo"
      src="../assets/img/nymblscience-logo.svg"
    >
    <h1>403</h1>
    <h2>Sorry! You do not have permission to access this location.</h2>
  </div>
</template>

<script>
export default {
  name: "Restricted",
  created() {
    // do something after creating vue instance
    this.$store.commit("setLoading", false);
  },
};
</script>
<style media="screen" lang="scss">
.restricted-page {
  img {
    margin: 0 auto;
    display: block;
    min-width: 200px;
    max-width: 300px;
  }
  h1 {
    font-size: 3rem;
    text-align: center;
    font-weight: bold;
  }
  h2 {
    max-width: 400px;
    margin: 0 auto;
    font-size: 1.4rem;
    text-align: center;
  }
  max-width: 500px;
  margin: 100px auto 0 auto;
}
</style>

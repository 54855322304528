<template>
  <div
    v-if="$auth.user()"
    class="navigation-profile-toggle"
    :class="{ 'navigation-profile-toggle-open': showProfile }"
  >
    <div class="navigation-toggle-container flex items-center">
      <n-button-icon
        class="navigation-toggle-button"
        variant="transparent"
      >
        <account-icon class="navigation-toggle-button-icon" />
      </n-button-icon>

      <div class="navigation-profile-toggle-user select-none">
        <span class="navigation-profile-toggle-first">
          {{ $auth.user().name }}
        </span>
        <span class="navigation-profile-toggle-second">{{ companyName }}</span>
      </div>
      <chevron-down
        class="navigation-profile-arrow"
        :class="{ 'transform rotate-180 transition-transform': showProfile }"
      />
    </div>
    <slot />
  </div>
</template>

<script>
import AccountIcon from "vue-material-design-icons/Account.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";

export default {
  name: "NavigationProfileToggle",
  components: {
    AccountIcon,
    ChevronDown,
  },

  props: ["showProfile", "companyName"],
};
</script>

<style lang="scss">
.navigation-profile-toggle {
  border-radius: 3px;
  margin-left: 1rem;
  display: flex;
  position: relative;
  transition: 300ms all;
  line-height: 1.5;
  &:focus {
    color: #292f2f;
    outline: none;
  }
  &:active {
    color: #292f2f;
  }
  .navigation-toggle-button {
    display: none;
  }
}

.navigation-toggle-container {
  cursor: pointer;
  display: block;
  display: flex;
  position: relative;
}

.navigation-profile-arrow {
  transition: 100ms all;
  align-self: center;
  margin-left: 0.5rem;
}

.navigation-profile-toggle-user {
  align-self: flex-start;
}

.navigation-profile-toggle-first {
  font-weight: 600;
  font-size: 0.8rem;
  color: #292f2f;
  display: block;
}

.navigation-profile-toggle-second {
  font-size: 0.8rem;
  color: #595959;
  display: block;
}

@media only screen and (max-width: 70rem) {
  .navigation-profile-toggle .navigation-profile-toggle-user,
  .navigation-profile-arrow {
    display: none;
  }
  .navigation-profile-toggle .navigation-toggle-button {
    display: block;
  }

  .navigation-profile-toggle {
    margin-left: 0rem;
  }

  .navigation-profile-toggle .btn-icon {
    padding-right: 0;
    padding-left: 0;
  }
  .navigation-profile-toggle:hover,
  .navigation-profile-toggle:focus,
  .navigation-profile-toggle:active {
    background: #fff;
  }
}

</style>
import { createRouter, createWebHistory } from "vue-router";

import SignIn from "@/views/Auth/AuthSignIn.vue";
import AuthForm from "@/views/Auth/AuthForm.vue";
import AuthMfa from "@/views/Auth/AuthMfa.vue";
import AuthMfaSetup from "@/views/Auth/AuthMfaSetup.vue";
import ForgotPassword from "@/views/Auth/ForgotPassword.vue";
import ForgotPasswordSuccess from "@/views/Auth/ForgotPasswordSuccess.vue";
import ResetPassword from "@/views/Auth/AuthResetPassword.vue";

import Forbidden from "@/views/403.vue";
import PageNotFound from "@/views/404.vue";
import Redirect from "@/views/Redirect.vue";

import userRoutes from "./userRoutes.js";
import accountAdminRoutes from "./accountAdminRoutes.js";
import companiesRoutes from "./companiesRoutes.js";

import contentAdminRoutes from "./contentAdminRoutes.js";

import systemAdminRoutes from "./systemAdminRoutes.js";

const routes = [
  {
    path: "/SignIn",
    name: "SignIn",
    component: SignIn,
    meta: {
      isNavbarDisabled: true,
    },
    children: [
      {
        path: "",
        name: "AuthForm",
        component: AuthForm,
      },
      {
        path: "/SignIn/Mfa",
        name: "AuthMfa",
        component: AuthMfa,
      },
      {
        path: "/SignIn/MfaSetup",
        name: "AuthMfaSetup",
        component: AuthMfaSetup,
      },
      {
        path: "/SignIn/MfaSetup/Verify",
        name: "AuthMfaSetupVerify",
        component: AuthMfa,
      },
      {
        path: "ForgotPassword",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "ResetEmailSent",
        name: "ForgotPasswordSuccess",
        component: ForgotPasswordSuccess,
      },
    ],
  },

  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      isNavbarDisabled: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
    meta: {
      isNavbarDisabled: true,
    },
    name: "404",
  },
  {
    path: "/r",
    component: Redirect,
    meta: {
      isNavbarDisabled: true,
    },
    name: "Redirect",
  },
  {
    path: "/403",
    name: "403",
    component: Forbidden,
    meta: {
      isNavbarDisabled: true,
    },
  },
  {
    path: "/",
    name: "Home",
    meta: {
      isNavbarDisabled: true,
      auth: {
        redirect: "/SignIn",
      },
    },
  },
  ...userRoutes,
  ...companiesRoutes,
  ...accountAdminRoutes,
  ...contentAdminRoutes,
  ...systemAdminRoutes,
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition;
      // For anchors
    }
    if (to.hash) {
      return { selector: to.hash };
      // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    }
    if (from.path === to.path) {
      return {};
    }

    // Scroll to top
    return { left: 0, top: 0 };
  },

  history: createWebHistory(process.env.BASE_URL),

  routes,
});

export default (app) => {
  app.router = router;

  app.use(router);
};

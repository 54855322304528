<template>
  <div>
    <h1 class="text-green-100 m-0 text-center text-base font-extrabold">
      Setup MFA
    </h1>
    <p class="text-xs text-center mt-3 mb-2">
      Scan QR code with an authenticator app
    </p>

    <div
      v-show="!loading"
      class="flex justify-center items-center flex-col p-2"
    >
      <qrcode-vue
        :size="150"
        :value="uri"
      />
      <div class="flex flex-col m-2 mt-2">
        <span class="text-xxs">Secret code:</span>
        <span class="text-xxs p-1 px-2 rounded-sm bg-gray-100">{{
          sharedKey
        }}</span>
      </div>
    </div>

    <div class="text-center">
      <n-button
        :loading="loadingVerify"
        @click="next"
      >
        Next
      </n-button>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import QrcodeVue from "qrcode.vue";

export default {
  name: "AuthMfaSetup",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      code: null,
      email: "",
      sharedKey: null,
      recoveryCodes: [],
      uri: "olaceholder",
      loading: false,
      loadingVerify: false,
    };
  },
  created() {
    this.generateMfa();
  },

  methods: {
    generateMfa() {
      this.loading = true;

      api
        .post(`/Authorization/MFA/Generate`)
        .then(({ data }) => {
          this.sharedKey = data.sharedKey;
          this.uri = data.authenticatorUri;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading = false;
        });
    },
    next() {
      this.$router.push({ name: "AuthMfaSetupVerify" });
    },
  },
};
</script>

<script>
export default {
  name: "Redirect",
  computed: {
    me() {
      return this.$store.state.me;
    },
    roles() {
      return Object.keys(this.me).length > 0 ? this.$store.state.me.roles : [];
    },
  },
  mounted() {
    this.redirect();
  },
  methods: {
    redirect() {
      if (Object.keys(this.me).length > 0) {
        this.$router.push({ name: "Dashboard" });
      } else {
        window.App.logout();
        return;
      }
    },
  },
};
</script>

import { resetMfaState } from "./views/Auth/mfaStore.js";

const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
  resetMfaState();
};

const closeModals = () => {
  const modals = document.querySelectorAll(".n-modal");
  modals.forEach((modal) => {
    modal.remove();
  });
};

const logout = (context, store, auth, route) => {
  // Remember the route when session expired
  if (context === "expire") {
    store.commit("updateRedirectPathExpire", route.fullPath);
  }

  if (context !== "SignIn") {
    // Restore VUEX to the original state
    store.commit("resetState");
    clearStorage();
  }

  // Logout from other tabs
  store.commit("updateLoginStatus", false);

  setTimeout(() => {
    // Clear The local storage from tokens and expiry dates after logout
    clearStorage();
    closeModals();
  }, 1000);

  return auth.logout({
    success() {
      console.log("Logged out successfully");
    },
    error() {
      console.log("Error while logging out");
    },
  });
};

export { logout };

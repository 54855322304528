<template>
  <div ref="profile" class="navigation-profile" @keyup.esc="close">
    <div class="navigation-profile-container">
      <div class="navigation-profile-user select-none">
        <div class="navigation-profile-name">
          {{ $auth.user().name }}
        </div>
        <div class="navigation-profile-email">
          {{ companyName }}
        </div>
      </div>

      <div class="navigation-profile-title">Accounts</div>
    </div>
    <nav vertical>
      <n-nav-item
        v-if="$auth.check('Account Admin')"
        class="w-100"
        @click="switchRole('Account Admin')"
      >
        Account Admin
      </n-nav-item>
      <n-nav-item
        v-if="$auth.check('Content Admin') || $auth.check('Account Admin')"
        class="w-100"
        @click="switchRole('Content Admin')"
      >
        Content Admin
      </n-nav-item>
      <n-nav-item
        v-if="$auth.check('System Admin')"
        class="w-100"
        @click="switchRole('System Admin')"
      >
        System Admin
      </n-nav-item>
    </nav>

    <hr />
    <div class="navigation-profile-container">
      <n-button
        class="float-right"
        variant="danger"
        button-type="text"
        size="sm"
        @click="$emit('logout')"
      >
        Logout
      </n-button>
    </div>
  </div>
</template>
<script setup>
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

const profile = ref(null);

const emit = defineEmits(["close"]);

onClickOutside(profile, (event) => {
  //Don't fire a close event if toggle button is pressed.
  if (
    event
      .composedPath()
      .some(
        (el) =>
          el.classList && el.classList.contains("navigation-profile-toggle")
      )
  ) {
    return;
  }

  emit("close");
});
</script>

<script>
export default {
  name: "NavigationProfile",

  props: ["showProfile", "companyName"],
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    isImpersonating() {
      return this.$store.state.impersonating;
    },
  },
  watch: {
    companyName(newValue) {
      console.log(newValue + " impersonate:" + this.isImpersonating);
    },
  },
  created() {
    // do something after creating vue instance
    const that = this;
    setTimeout(() => {
      that.loaded = true;
    }, 200);
  },
  methods: {
    close(event) {
      console.log(event);
      //Don't fire a close event if toggle button is pressed.
      if (event.target.textContent === "Account icon") {
        return;
      }
      if (this.loaded === false) {
        return;
      }
      this.$emit("close");
    },
    switchRole(role) {
      let that = this;
      setTimeout(function () {
        if (role === "System Admin") {
          that.$router.push("/SystemAdmin/FeatureFlags/FeatureKeys");
        }
        if (role === "Account Admin") {
          that.$router.push("/Users");
        }
        if (role === "Content Admin") {
          that.$router.push("/ContentAdmin");
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.n-navbar .navigation-profile {
  border-radius: 0.1rem;
  position: absolute;
  background: #ffffff;
  top: 4.3rem;
  right: 0;
  z-index: 2008;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  min-width: 14rem;
  padding-bottom: 0.7rem;
  padding-top: 1rem;
  line-height: 1.5;

  .nav-item {
    transition: 400ms;

    &:hover {
      background: rgba(32, 33, 36, 0.039);
    }

    &:focus {
      background: rgba(32, 33, 36, 0.039);
    }

    &:active {
      background: rgba(32, 33, 36, 0.045);
    }
  }

  .nav-link {
    transition: 400ms;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
    font-size: 0.85rem;
  }

  hr {
    border-color: rgba(32, 33, 36, 0.045);
    border-width: 0.03rem;
    margin-bottom: 0.7rem;
  }
}

.navigation-profile-container {
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}

.navigation-profile-title {
  text-transform: uppercase;
  font-size: 0.65rem;
  margin-bottom: 0.3rem;
  color: #222;
}

.navigation-profile-user {
  display: none;
  margin-bottom: 1rem;
}

.navigation-profile-name {
  font-weight: 600;
  font-size: 0.8rem;
  color: #111;
  display: block;
}

.navigation-profile-email {
  font-size: 0.8rem;
  color: #666;
  display: block;
}

@media only screen and (max-width: 70rem) {
  .navigation-profile-user {
    display: block;
  }
}

.slide-enter-active,
.slide-leave-active {
  opacity: 1;
  transition: opacity 200ms;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>

import { createStore } from "vuex";

import axios from "axios";
import { apiUrl } from "@/common/apiUrl";
import createPersistedState from "vuex-plugin-persistedstate";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  window: {
    width: 0,
    height: 0,
  },
  device: "is-desktop",
  openedModals: [],
  loading: false,
  apiError: false,
  impersonating: false,
  impersonatingEmail: "",
  oldToken: "",
  oldMe: [],
  primaryToken: {},
  primaryUrl: apiUrl,
  activeApi: "primary",
  location: null,
  navbar: true,
  redirectPathExpire: "",
  me: {},
  mePrimary: {},
  meCompany: null,
  defaults: [],
  anonymousDefaults: {},
  anonymousFeatureFlags: null,

  isLoggedIn: true,
  accountAdmin: {
    companies: [],
    users: [],
    usersPageCount: 1,
    trainingProgramProfiles: [],
    coaches: [],
    companyProfiles: [],
  },
  contentAdmin: {
    trainingProgramProfiles: [],
    trainingPrograms: [],
    trainingLevels: [],
    exercises: [],
    promotionSchemes: [],
    dualTasks: [],
    dualTaskTags: [],
  },
  coach: {
    fullTrainingProgramHierarchy: [],
    walkTests: false,
    walkTestUsers: [],
    userList: [],
    usersTraining: [],
    usersEvaluation: [],
    allCompanyUsers: [],
    selectedUser: [],
    filterValue: "",
    timeInterval: 14,
  },
  skipPasswordEligibilityFlow: true,
  tokenEligibilityFlow: null,
};

export const store = createStore({
  state: { ...initialState },
  strict: false,
  mutations: {
    // Reset state to initial on log out
    resetState(state) {
      const redirectPathExpire = cloneDeep(state.redirectPathExpire);

      Object.assign(state, cloneDeep(initialState));
      state.redirectPathExpire = redirectPathExpire;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },
    setApiError(state, apiError) {
      console.log("Api Error: ", apiError);
      state.apiError = apiError;
      setTimeout(() => {
        state.apiError = false;
      }, 1000);
    },
    setImpersonating(state, { impersonating, email, oldToken, oldMe, token }) {
      console.log("Impersonating portal as ", email);
      state.impersonating = impersonating;
      state.impersonatingEmail = email;
      state.oldToken = oldToken;
      (state.oldMe = oldMe), (state.token = token);
    },
    setNavbar(state, navbar) {
      state.navbar = navbar;
    },
    changeLoadingState(state, loading) {
      state.loading = loading;
    },
    updateMe(state, user) {
      state.mePrimary = user;
      state.me = user;
    },

    switchToPrimaryMe(state) {
      state.me = { ...state.mePrimary };
    },
    switchToSecondaryMe(state) {
      state.me = { ...state.meSecondary };
    },
    updateMeCompany(state, data) {
      state.meCompany = data;
    },

    updateDefaults(state, defaults) {
      state.defaults = defaults;
    },
    updateAnonymousDefaults(state, anonymousDefaults) {
      state.anonymousDefaults = anonymousDefaults;
    },
    updateAnonymousFeatureFlags(state, anonymousFeatureFlags) {
      state.anonymousFeatureFlags = anonymousFeatureFlags;
    },
    updateRedirectPathExpire(state, redirectPathExpire) {
      console.log("Updating redirectPathExpire", redirectPathExpire, state);
      state.redirectPathExpire = redirectPathExpire;
    },
    // Coach
    updateFullTrainingProgramHierarchy(state, fullTrainingProgramHierarchy) {
      state.coach.fullTrainingProgramHierarchy = fullTrainingProgramHierarchy;
    },
    updateWalkTests(state, walkTests) {
      state.coach.walkTests = walkTests;
    },
    updateWalkTestUsers(state, walkTestUsers) {
      state.coach.walkTestUsers = walkTestUsers;
    },
    updateFilter(state, filterValue) {
      state.coach.filterValue = filterValue;
    },
    updateUserList(state, userList) {
      state.coach.userList = userList;
    },
    updateUsersTraining(state, users) {
      state.coach.usersTraining = users;
    },
    updateUsersEvaluation(state, users) {
      state.coach.usersEvaluation = users;
    },
    updateAllCompanyUsers(state, allCompanyUsers) {
      state.coach.allCompanyUsers = allCompanyUsers;
    },
    updateTimeInterval(state, timeInterval) {
      state.coach.timeInterval = timeInterval;
    },
    selectUser(state, user) {
      state.coach.selectedUser = user;
    },

    // Account Admin
    updateCompanies(state, companies) {
      state.accountAdmin.companies = companies;
    },

    updateCompanyProfiles(state, companyProfiles) {
      state.accountAdmin.companyProfiles = companyProfiles;
    },

    updateUsers(state, users) {
      state.accountAdmin.users = users;
    },
    updateUsersPageCount(state, usersPageCount) {
      state.accountAdmin.usersPageCount = usersPageCount;
    },
    updateCoaches(state, coaches) {
      state.accountAdmin.coaches = coaches;
    },
    updateProfiles(state, trainingProgramProfiles) {
      state.accountAdmin.trainingProgramProfiles = trainingProgramProfiles;
    },

    updateTrainingProgramProfiles(state, trainingProgramProfiles) {
      state.contentAdmin.trainingProgramProfiles = trainingProgramProfiles;
    },
    updateTrainingPrograms(state, trainingPrograms) {
      state.contentAdmin.trainingPrograms = trainingPrograms;
    },
    updateTrainingLevels(state, trainingLevels) {
      state.contentAdmin.trainingLevels = trainingLevels;
    },
    updateExercises(state, exercises) {
      state.contentAdmin.exercises = exercises;
    },
    updatePromotionSchemes(state, promotionSchemes) {
      state.contentAdmin.promotionSchemes = promotionSchemes;
    },
    updateDualTasks(state, dualTasks) {
      state.contentAdmin.dualTasks = dualTasks;
    },
    updateDualTaskTags(state, dualTaskTags) {
      state.contentAdmin.dualTaskTags = dualTaskTags;
    },
    updateWindow(state, window) {
      state.window = window;
    },
    updateDevice(state, device) {
      state.device = device;
    },
    updatePrimaryToken(state, data) {
      state.primaryToken = data;
    },
    updatePrimaryUrl(state, url) {
      state.primaryUrl = url;
    },
    updateSecondaryToken(state, data) {
      state.secondaryToken = data;
    },
    updateActiveApi(state, data) {
      state.activeApi = data;
    },
    updateLoginStatus(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    updateLocation(state, location) {
      state.location = location;
    },
    updateOpenedModals(state, modal) {
      state.openedModals.push(modal);
    },
    removeFromOpenedModals(state, modal) {
      const filteredModals = state.openedModals.filter(
        (openedModal) => openedModal._uid !== modal._uid
      );
      state.openedModals = filteredModals;
    },
    clearOpenedModals(state) {
      state.openedModals = [];
    },
    setSkipPasswordEligibilityFlow(state, value) {
      state.skipPasswordEligibilityFlow = value;
    },
    setTokenEligibilityFlow(state, value) {
      state.tokenEligibilityFlow = value;
    },
  },
  getters: {
    isFallsFree: () => {
      const host = window.location.host.split(".");

      if (host[0] === "be" || host[0] === "be-test") {
        return true;
      }
      return false;
    },
    isTestEnv: () => {
      if (process.env.VUE_APP_ENV === "production") {
        return false;
      }
      return true;
    },
    apiToCountryMappings: (state) => {
      const mapCountriesToLowerCase = (apiToCountryMappings) => {
        Object.keys(apiToCountryMappings).forEach((key) => {
          apiToCountryMappings[key.toLowerCase()] = apiToCountryMappings[key];

          if (key.toLowerCase() !== key) {
            delete apiToCountryMappings[key];
          }
        });
        return apiToCountryMappings;
      };

      return Object.keys(state.anonymousDefaults).length > 0
        ? mapCountriesToLowerCase(state.anonymousDefaults.apiToCountryMappings)
        : false;
    },
  },
  actions: {
    // Coach Getters
    getMe({ commit }) {
      commit("setLoading", true);
      axios
        .get(
          "/Me?req.includeFullTrainingProgramHierarchy=true&req.includeCompanies=true"
        )
        .then((response) => {
          commit("updateMe", response.data);
          commit("setLoading", false);
        });
    },
    getMeSecondary({ commit }) {
      commit("setLoading", true);
      axios
        .get(
          "/Me?req.includeFullTrainingProgramHierarchy=true&req.includeCompanies=true"
        )
        .then((response) => {
          commit("updateMeSecondary", response.data);
          commit("setLoading", false);
        });
    },
    // Account Admin Getters
    getCompanies({ commit }) {
      commit("setLoading", true);
      axios.get("/Company/All?includeCoaches=true").then((response) => {
        commit("updateCompanies", response.data);
        commit("setLoading", false);
      });
    },

    getCompanyProfiles({ commit }) {
      commit("setLoading", true);
      axios.get("/Company/Profiles").then((response) => {
        commit("updateCompanyProfiles", response.data);
        commit("setLoading", false);
      });
    },

    getUsers(
      { commit },
      flags = {
        includeEnhancedSecurityUsers: false,
        includeDeceased: false,
        includeArchived: false,
        includeInactive: false,
        includeDemoUsers: false,
      }
    ) {
      commit("setLoading", true);

      if (
        flags.includeEnhancedSecurityUsers ||
        flags.includeInactive ||
        flags.includeArchived ||
        flags.includeDeceased ||
        flags.includeDemoUsers
      ) {
        commit("updateUsers", []);
      }
      axios
        .get("/Admin/UsersBriefPaged", { params: flags })
        .then((response) => {
          commit("updateUsers", response.data.items);

          commit("updateUsersPageCount", response.data.pageCount);
          commit("setLoading", false);
        });
    },
    getCoaches({ commit }) {
      commit("setLoading", true);
      axios.get("/Admin/Coaches").then((response) => {
        commit("updateCoaches", response.data);
        commit("setLoading", false);
      });
    },
    getTrainingProgramProfiles({ commit }) {
      commit("setLoading", true);
      axios.get("/TrainingProgramProfiles").then((response) => {
        commit("updateProfiles", response.data);
        commit("setLoading", false);
      });
    },

    // Content Admin Getters
    getProgramProfiles({ commit }) {
      commit("setLoading", true);
      axios.get("/TrainingProgramProfiles").then((response) => {
        commit("updateTrainingProgramProfiles", response.data);
        commit("setLoading", false);
      });
    },
    getTrainingPrograms({ commit }) {
      commit("setLoading", true);
      axios.get("/TrainingPrograms").then((response) => {
        commit("updateTrainingPrograms", response.data);
        commit("setLoading", false);
      });
    },
    getTrainingLevels({ commit }) {
      commit("setLoading", true);
      axios.get("/TrainingLevels?includeDetails=true").then((response) => {
        commit("updateTrainingLevels", response.data);
        commit("setLoading", false);
      });
    },
    getExercises({ commit }) {
      commit("setLoading", true);
      axios.get("/Exercises").then((response) => {
        commit("updateExercises", response.data);
        commit("setLoading", false);
      });
    },
    getPromotionSchemes({ commit }) {
      commit("setLoading", true);
      axios.get("/PromotionSchemes").then((response) => {
        commit("updatePromotionSchemes", response.data);
        commit("setLoading", false);
      });
    },
    getDualTasks({ commit }, payload) {
      commit("setLoading", true);
      let url = "/DualTasks";
      if (payload && payload.enterpriseId) {
        url = `/DualTasks?enterpriseId=${payload.enterpriseId}`;
      }
      axios.get(url).then((response) => {
        commit("updateDualTasks", response.data);
        commit("setLoading", false);
      });
    },
    getDualTaskTags({ commit }) {
      commit("setLoading", true);
      axios.get("/DualTaskTags").then((response) => {
        commit("updateDualTaskTags", response.data);
        commit("setLoading", false);
      });
    },
  },
  plugins: [
    createPersistedState({
      key: "vuex",
      overwrite: true,
    }),
  ],
});

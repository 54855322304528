<template>
  <auth-container>
    <n-form
      v-if="!success"
      id="loginForm"
      class="reset-pw"
      @submit.prevent="submitResetPasswordForm()"
    >
      <n-form-item class="mt-5">
        <n-textbox
          id="password"
          ref="passwordRef"
          v-model="password"
          border
          :input-classes="{
            'rounded-xxs placeholder:italic text-xs py-0 h-8  border-gray-light': true,
            'border-red': isInvalid('password', v$),
          }"
          :error="getError('password', v$)"
          :invalid="isInvalid('password', v$)"
          auto-complete="off"
          name="Password"
          label="New Password"
          type="password"
          placeholder="Enter your new password"
          @change="v$.password.$touch"
        >
          <label class="text-xs font-bold">Password:</label>
        </n-textbox>
      </n-form-item>
      <n-form-item>
        <n-textbox
          id="confirmPassword"
          v-model="confirmPassword"
          border
          placeholder="Confirm password"
          :input-classes="{
            'rounded-xxs placeholder:italic text-xs py-0 h-8  border-gray-light': true,
            'border-red': isInvalid('confirmPassword', v$),
          }"
          label="Confirm Password"
          auto-complete="off"
          name="ConfirmPassword"
          type="password"
          :error="getError('confirmPassword', v$)"
          :invalid="isInvalid('confirmPassword', v$)"
          @change="v$.confirmPassword.$touch"
        >
          <label class="text-xs font-bold">Confirm Password:</label>
        </n-textbox>
      </n-form-item>

      <n-form-item>
        <n-form-item class="flex justify-center">
          <n-button
            :loading="loading"
            type="submit"
            native-type="submit"
            container-classes="text-xs py-1 px-8 font-medium"
            class="shadow text-xxs mb-2 mx-auto"
            rounded-xl
          >
            Reset Password
          </n-button>
        </n-form-item>
      </n-form-item>
    </n-form>
    <div
      v-if="success"
      class="reset-pw-success text-center"
    >
      <div class="flex justify-center">
        <checkbox-marked-circle-outline
          :size="72"
          class="text-green-200 m-8 self-middle"
        />
      </div>
      <p class="text-xl">
        Your password has been reset successfully.
      </p>
      <n-button
        v-if="source && source === 'Dashboard'"
        class="mt-3"
        @click="$router.push('/SignIn')"
      >
        Sign In
      </n-button>
    </div>
  </auth-container>
</template>

<script>
import axios from "axios";
import AuthContainer from "@/views/Auth/AuthContainer.vue";
import CheckboxMarkedCircleOutline from "vue-material-design-icons/CheckboxMarkedCircleOutline.vue";
import { ref } from "vue";

import { useValidation, getError, isInvalid } from "nymbl-ui/validation";
import { required, helpers, sameAs } from "@vuelidate/validators";
export default {
  name: "ResetPassword",
  components: {
    CheckboxMarkedCircleOutline,
    AuthContainer,
  },

  setup() {
    const password = ref("");
    const confirmPassword = ref("");

    const rules = {
      password: {
        required: helpers.withMessage(
          "The Password field is required",
          required
        ),
      },
      confirmPassword: {
        required: helpers.withMessage(
          "The Confirm Password field is required",
          required
        ),
        sameAs: helpers.withMessage(
          "The Confirm Password field must be the same as the Password field",
          sameAs(password)
        ),
      },
    };

    const v$ = useValidation(
      rules,
      { password, confirmPassword },
      { $lazy: true }
    );

    return {
      v$,
      password,
      confirmPassword,
      getError,
      isInvalid,
    };
  },

  data() {
    return {
      loading: false,
      loaded: false,
      failed: false,
      success: false,
      source: null,
      resetPasswordForm: {
        email: "",
        token: "",

        password: "",
        confirmPassword: "",
        apiLocation: "",
      },
    };
  },
  computed: {
    apiLocation() {
      return this.$route.query.apiLocation
        ? this.$route.query.apiLocation
        : null;
    },
    anonymousDefaults() {
      return this.$store.state.anonymousDefaults;
    },
    apiToCountryMappings() {
      return Object.keys(this.anonymousDefaults).length > 0
        ? this.anonymousDefaults.apiToCountryMappings
        : false;
    },
    apiUrl() {
      if (!this.apiToCountryMappings || !this.apiLocation) {
        return null;
      }

      const url = Object.keys(this.apiToCountryMappings).filter(
        (country) => country.toLowerCase() === this.apiLocation.toLowerCase()
      );

      return url.length > 0
        ? this.apiToCountryMappings[url]
        : this.apiToCountryMappings["other"];
    },
    passwordValidateEvent() {
      if (this.resetPasswordForm.password.length > 0) {
        return "change";
      }
      return "change";
    },
    confirmPasswordValidateEvent() {
      if (this.resetPasswordForm.confirmPassword.length > 0) {
        return "change";
      }
      return "change";
    },
  },
  watch: {
    // Cancel loading when Api Call has an error.
    apiError(value) {
      const that = this;
      if (value === true) {
        that.loading = false;
      }
    },
  },
  created() {
    const that = this;

    // Populate form from the url queries
    this.resetPasswordForm.email = this.$route.query.email.replace(/\s/g, "+");
    this.resetPasswordForm.token = this.$route.query.token.replace(/\s/g, "+");
    this.source = this.$route.query.source.replace(/\s/g, "+");

    setTimeout(() => {
      that.loaded = true;
    }, 100);
  },
  methods: {
    submitResetPasswordForm() {
      const data = this.resetPasswordForm;
      data.password = this.password;
      data.confirmPassword = this.confirmPassword;
      data.apiLocation = this.apiLocation;
      const that = this;

      this.v$.$validate().then((isValid) => {
        if (isValid) {
          that.loading = true;
          if (!this.apiUrl) {
            throw new Error("No API url found.");
          }
          axios
            .post(`${this.apiUrl}/Auth/PasswordReset`, data)
            .then(() => {
              that.success = true;
              that.loading = false;
            })
            .catch((error) => {
              that.$nMessage({
                type: "error",
                message: "Your password could not be reset.",
                showClose: true,
                duration: 0,
              });

              that.loading = false;

              that.$store.commit("setApiError", true);
              console.log(error.response.data.message);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/animations.scss";
@import "@/assets/sass/colors.scss";
.reset-pw {
  text-align: left;
}

.reset-pw-success {
  text-align: center;

  font-weight: 600;
  font-size: 2rem;
  line-height: 1.6;
  margin: 0;
  margin-top: -50px;
}
</style>

<template>
  <div>
    <n-modal
      ref="modal"
      class="n-confirm"
      role="alertdialog"
      :dismissible="!loading"
      :title="title"
      @close="onModalClose"
      @hide="onModalHide"
      @open="onModalOpen"
      @reveal="onModalReveal"
    >
      <div class="n-confirm__message">
        <slot :data="body">
          {{ body }}
        </slot>
      </div>
      <template #footer>
        <div class="n-confirm__footer">
          <n-button
            ref="denyButton"
            :disabled="loading"
            button-type="text"
            variant="danger"
            @click="handleReject"
          >
            {{ rejectText }}
          </n-button>
  
          <n-button
            ref="confirmButton"
            button-type="text"
            :loading="loading"
            @click="handleConfirm"
          >
            {{
              confirmText
            }}
          </n-button>
        </div>
      </template>
    </n-modal>
  </div>
</template>

<script>
import {ref} from 'vue';

import NymblUI from "nymbl-ui";

const { Components } = NymblUI;

export default {
    name: 'Confirm',
    components: {
        NModal: Components.NModal,
        NButton: Components.NButton,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        body: {
            type: String,
            default: '',
        },
        confirmText: {
            type: String,
            default: 'Ok',
        },
        rejectText: {
            type: String,
            default: 'Cancel',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        resolve: {
            type: Function,
        },
    },
    setup(props) {
        const modal = ref(null);
        const handleClose = () => {
            if (props.resolve) {
                props.resolve(null);
            }
        }

        const handleConfirm = () => {
            if (props.resolve) {
                props.resolve(true);
                modal.value.close()
            }
        }

        const handleReject = () => {
            if (props.resolve) {
                props.resolve(false);
                modal.value.close()
            }
        }

        return {
            handleClose,
            handleConfirm,
            handleReject,
            modal,
        };
    },
    mounted() {
        this.modal.open();
    },
    methods: {
        close() {
            this.modal.close();
        },
    }
};
</script>